import React from 'react'
import { Link } from 'gatsby'
import { Card, Button, Col, Row } from 'react-bootstrap'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Span from '../components/Span'
import '../components/css/promo.css'
import CashbackBanner from 'images/cashback-to-business.png'
import ReferralBanner from 'images/referal-times-two.png'
import Seo from '../components/Seo'
import DigiPadalaAgentKNB from 'images/digipadala-agent-knb.png'

const Promo = () => (
  <div>
    <Seo
      title="Digipay PH | Promos"
      description="Digipay is a digital payments and financial services platform with
a mobile wallet, an industry leading biller ecosystem,
and an interoperable backend."
      keywords="digipay.ph/promos, Digipay, Promos, Digipay Promos, Raffle, Agents"
    />
    <Header></Header>
    <div className="promo">
      <h1 align="center" id="font-weight-bold padding-bottom-md">
        DIGIPAY PROMOS
        <hr />
      </h1>
      <Row className="promo__items">
        <Col {...componentProps.cardItemWrapperProps}>
          <Card className="digipay-stories__story-content">
            <Card.Img
              src={CashbackBanner}
              {...componentProps.cardItemImageProps}
            />
            <Card.Body>
              <Card.Title className="digipay-stories__story-title">
                <Span color="#eb3236">CASHBACK TO BUSINESS</Span>
                <br />
                <Span>February 1-28, 2022</Span>
              </Card.Title>
              <Card.Text className="digipay-stories__story-description">
                Get P100 cashback when you sign up with a minimum top-up amount
                of P2,500 and transact an accumulated e-load transaction amount
                of P2,500
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Link to="/promos/cashbacktobusiness/">
                <Button {...componentProps.readMoreButtonProps}>
                  Read More
                </Button>
              </Link>
            </Card.Footer>
          </Card>
        </Col>
        <Col {...componentProps.cardItemWrapperProps}>
          <Card className="digipay-stories__story-content">
            <Card.Img
              src={DigiPadalaAgentKNB}
              {...componentProps.cardItemImageProps}
            />
            <Card.Body>
              <Card.Title className="digipay-stories__story-title">
                <Span color="#eb3236">DIGIPADALA AGENT KNB?</Span>
                <br />
                <Span>February 1-28, 2022</Span>
              </Card.Title>
              <Card.Text className="digipay-stories__story-description">
                Get P300 Digipay credits when you convert from a regular agent
                to a DigiPadala Agent
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Link to="/promos/DigiPadalaAgentKNB/">
                <Button {...componentProps.readMoreButtonProps}>
                  Read More
                </Button>
              </Link>
            </Card.Footer>
          </Card>
        </Col>

        <Col {...componentProps.cardItemWrapperProps}>
          <Card className="digipay-stories__story-content">
            <Card.Img
              src={ReferralBanner}
              {...componentProps.cardItemImageProps}
            />
            <Card.Body>
              <Card.Title className="digipay-stories__story-title">
                <Span color="#eb3236">REFERRAL MO TIMES TWO</Span>
                <br />
                <Span>February 1-28, 2022</Span>
              </Card.Title>
              <Card.Text className="digipay-stories__story-description">
                Get a P100 referral fee when you successfully refer an agent
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Link to="/promos/ReferralFeeMoTimesTwo/">
                <Button {...componentProps.readMoreButtonProps}>
                  Read More
                </Button>
              </Link>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </div>
    <Footer />
  </div>
)

const componentProps = {
  cardItemWrapperProps: {
    className: 'digipay-stories__story',
    align: 'center',
    md: 4,
  },
  cardItemImageProps: {
    variant: 'top',
    alt: 'guide-bg',
  },
  readMoreButtonProps: {
    className: 'digipay-stories__btn-read-more',
    variant: 'danger',
  },
}

export default Promo
